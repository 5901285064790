import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import initialState from './state.js'
import getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: initialState(),
  getters,
  mutations,
  actions,
  strict: import.meta.env.MODE !== 'production',
  plugins: [createPersistedState()]
})
