import initialState from './state.js'

export default {
  // general mutations
  SHOW_SNACKBAR (state, data) {
    state.snackbar = {
      visible: true,
      timeout: data.timeout || 6000,
      message: data.message
    }
  },
  CLOSE_SNACKBAR (state) {
    state.snackbar.visible = false
  },
  // auth
  SET_ADMIN_SESSION (state, data) {
    state.token = data.token
    state.adminId = data.admin.id
    if (data.admin.permissions) {
      state.adminPermissions = data.admin.permissions.map((e) => e.permission.attribute)
    }
  },
  CLEAR_STATE (state) {
    Object.assign(state, initialState())
  },
  // refresh admin
  REFRESH_ADMIN_SESSION (state, data) {
    if (data.permissions) {
      state.adminPermissions = data.permissions.map((e) => e.permission.attribute)
    }
  },
  // ecosystems
  SET_ECOSYSTEMS (state, data) {
    state.ecosystems = data
  }
}
