<template>
  <v-app>
    <router-view />
    <v-snackbar
      top
      :value="snackbarOptions ? snackbarOptions.visible : false"
      :timeout="snackbarOptions ? snackbarOptions.timeout : 6000"
      @input="closeSnackbar"
    >
      {{ snackbarOptions ? snackbarOptions.message : '' }}
      <template #action>
        <v-btn
          color="primary"
          text
          @click="closeSnackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    snackbarOptions () {
      return this.$store.getters.snackbarOptions
    }
  },
  methods: {
    closeSnackbar () {
      this.$store.commit('CLOSE_SNACKBAR')
    }
  }
}
</script>

<style lang="scss">
@import './styles/variables.scss';
</style>

<style>
/* force white background for light application */
.theme--light.v-application {
  background: white !important;
}

/* force white background for dialog */
.v-application .v-dialog {
  background-color: white !important;
}

/* prevent scrolling background when nav drawer is open */
.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* custom link styling  */
.internal-primary-link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: bold !important;
}
.internal-primary-link:hover {
  text-decoration: underline !important;
  text-decoration-color: #18a5a5 !important;
}
.internal-secondary-link {
  text-decoration: underline !important;
  text-decoration-color: #18a5a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}
.unstyled-link {
  text-decoration: none !important;
  color: inherit !important;
}

/* override box shadows */
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
.v-btn--contained {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
button.v-pagination__item {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
.v-pagination__navigation {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

/* customize dialogs globally */
.v-dialog {
  border-radius: 15px !important;
}

/* common styles for data tables */
.data-table thead {
  background-color: hsl(180, 45%, 98%);
}
.data-table thead tr th {
  color: #234e52 !important;
}
.data-table .user-name {
  width: 100px;
}
.data-table .email {
  width: 100px;
}
.data-table tbody tr td .muted-details {
  font-size: 12px;
  color: #4a5568;
}
.data-table tbody tr .actions {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.data-table tbody tr:hover .actions {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
.data-table.large-row tbody tr {
  height: 60px;
}

/* common styles for simple data tables */
.simple-data-table thead {
  background-color: hsl(180, 45%, 98%);
}
.simple-data-table thead th {
  color: #234e52 !important;
}

/* style tab component */
.v-tabs .v-tabs-bar {
  background-color: hsl(180, 45%, 98%) !important;
}
.v-tabs .v-tabs-items {
  background-color: hsl(180, 45%, 98%) !important;
}

.dialog-close-button {
  position: absolute !important;
  top: 10px;
  right: 15px;
}
</style>
