export default {
  isLoggedIn (state) {
    if (state.token === null || state.adminId === null) return false
    else return true
  },
  snackbarOptions (state) {
    return state.snackbar
  },
  admin (state) {
    return {
      id: state.adminId,
      permissions: state.adminPermissions
    }
  },
  ecosystems (state) {
    return state.ecosystems
  }
}
