import request from './utils/request.js'
import {
  LOGIN_MUTATION,
  ADMIN_PROFILE_QUERY,
  ECOSYSTEMS_QUERY,
  STATIONS_QUERY,
  DOCK_QUERY,
  UPDATE_STATION_DETAILS_MUTATION,
  RESTART_DOCK,
  TRIGGER_DOCK,
  CREATE_STATION_MUTATION,
  UPDATE_DOCK_STATUS_MUTATION,
  ONGOING_RIDES_QUERY,
  STOP_RIDE_STATIONS_QUERY,
  STOP_RIDE_MUTATION,
  DELETE_ONGOING_RIDE_MUTATION,
  COMPLETED_RIDES_QUERY,
  ADJUST_RIDE_MUTATION,
  REFUND_RIDE_MUTATION,
  PAYMENTS_QUERY,
  PAYMENT_QUERY,
  CAPTURE_RAZORPAY_PAYMENT_MUTATION,
  REFUND_ONLINE_PAYMENT_MUTATION,
  REFUND_PASS_PURCHASE_MUTATION,
  OPERATOR_LOGS_QUERY,
  USERS_QUERY,
  TOGGLE_TEST_USER_MUTATION,
  BLOCK_USER_MUTATION,
  UNBLOCK_USER_MUTATION,
  USER_PROFILE_QUERY,
  CREATE_VOUCHER_PAYMENT_MUTATION,
  CREATE_DEBIT_PAYMENT_MUTATION,
  BIKES_QUERY,
  UPDATE_BIKE_HEXCODE_MUTATION,
  UPDATE_BIKE_STATE_MUTATION,
  UPDATE_BIKE_ECOSYSTEM_MUTATION,
  PENDING_BIKES_QUERY,
  DELETE_ALL_PENDING_BIKES_MUTATION,
  DEPLOY_PENDING_BIKE_MUTATION,
  DELETE_PENDING_BIKE_MUTATION,
  CREATE_BIKE_MUTATION,
  DOCKS_QUERY,
  CREATE_DOCK_MUTATION,
  UPDATE_DOCK_MUTATION,
  DELETE_DOCK_MUTATION,
  RIDE_RATINGS_QUERY,
  TASKS_QUERY,
  TASK_ISSUES_QUERY,
  TASKS_STATS_QUERY,
  CREATE_TASK_MUTATION,
  TASK_PHOTO_URL_MUTATION,
  UPDATE_TASK_MUTATION,
  ASSIGN_TASKS_MUTATION,
  DELETE_TASKS_MUTATION,
  REFERRALS_QUERY,
  REWARDS_QUERY,
  CUMULATIVE_ANALYTICS_QUERY,
  OPERATORS_QUERY,
  UPDATE_OPERATOR_ROLE_MUTATION,
  CREATE_OPERATOR_MUTATION,
  OPERATOR_PROFILE_QUERY,
  BLOCK_OPERATOR_MUTATION,
  UNBLOCK_OPERATOR_MUTATION,
  ADD_OPERATOR_ECOSYSTEM_MUTATION,
  DELETE_OPERATOR_ECOSYSTEM_MUTATION,
  PASSES_QUERY,
  PASS_QUERY,
  ECOSYSTEMS_WITHOUT_PASSES_QUERY,
  CREATE_PASS_MUTATION,
  TOGGLE_PASS_STATUS_MUTATION,
  ADD_PASS_ECOSYSTEMS_MUTATION,
  REMOVE_PASS_ECOSYSTEMS_MUTATION,
  PAGINATED_ECOSYSTEMS_QUERY,
  CREATE_ECOSYSTEM_MUTATION,
  UPDATE_ECOSYSTEM_MUTATION,
  OPERATOR_LOG_FOR_AUDIT_QUERY,
  AUDIT_OPERATOR_LOG_MUTATION,
  STORE_DEALS_QUERY,
  STORE_DEAL_QUERY,
  ADMINS_QUERY,
  ADMIN_PERMISSIONS_QUERY,
  CREATE_ADMIN_PERMISSION_MUTATION,
  ADMIN_QUERY,
  ADD_ADMIN_PERMISSION_MUTATION,
  REMOVE_ADMIN_PERMISSION_MUTATION,
  BLOCK_ADMIN_MUTATION,
  UNBLOCK_ADMIN_MUTATION
} from './graphql.js'

export default {
  // auth
  async login ({ commit, state }, payload) {
    const response = await request(LOGIN_MUTATION, { input: payload })

    if (response && response.data && response.data.loginAdmin) {
      commit('SET_ADMIN_SESSION', response.data.loginAdmin)
      return response.data.loginAdmin
    }
  },
  async refreshAdmin ({ commit, state }, payload) {
    const response = await request(ADMIN_PROFILE_QUERY, {}, state.token)

    if (response && response.data && response.data.currentAdmin) {
      commit('REFRESH_ADMIN_SESSION', response.data.currentAdmin)
      return response.data.currentAdmin
    }
  },
  // ecosystems
  async getEcosystems ({ commit, state }, payload) {
    const response = await request(ECOSYSTEMS_QUERY, {}, state.token)

    if (response && response.data && response.data.ecosystems) {
      commit('SET_ECOSYSTEMS', response.data.ecosystems)
      return response.data.ecosystems
    }
  },
  // stations
  async getStations ({ commit, state }, payload) {
    const response = await request(STATIONS_QUERY, payload, state.token)

    if (response && response.data && response.data.stations) {
      return response.data.stations
    }
  },
  async getDock ({ commit, state }, payload) {
    const response = await request(DOCK_QUERY, payload, state.token)

    if (response && response.data && response.data.dock) {
      return response.data.dock
    }
  },
  async updateStationDetails ({ commit, state }, payload) {
    const response = await request(UPDATE_STATION_DETAILS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateStationDetails) {
      return response.data.updateStationDetails
    }
  },
  async restartDock ({ commit, state }, payload) {
    const response = await request(RESTART_DOCK, { input: payload }, state.token)

    if (response && response.data && response.data.restartDock) {
      return response.data.restartDock
    }
  },
  async triggerDock ({ commit, state }, payload) {
    const response = await request(TRIGGER_DOCK, { input: payload }, state.token)

    if (response && response.data && response.data.triggerDock) {
      return response.data.triggerDock
    }
  },
  async createStation ({ commit, state }, payload) {
    const response = await request(CREATE_STATION_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createStation) {
      return response.data.createStation
    }
  },
  async updateDockStatus ({ commit, state }, payload) {
    const response = await request(UPDATE_DOCK_STATUS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateDockStatus) {
      return response.data.updateDockStatus
    }
  },
  // ongoing rides
  async getOngoingRides ({ commit, state }, payload) {
    const response = await request(ONGOING_RIDES_QUERY, payload, state.token)

    if (response && response.data && response.data.ongoingRides) {
      return response.data.ongoingRides
    }
  },
  async getStopStations ({ commit, state }, payload) {
    const response = await request(STOP_RIDE_STATIONS_QUERY, payload, state.token)

    if (response && response.data && response.data.stations) {
      return response.data.stations
    }
  },
  async stopRide ({ commit, state }, payload) {
    const response = await request(STOP_RIDE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.stopOngoingRide) {
      return response.data.stopOngoingRide
    }
  },
  async deleteOngoingRide ({ commit, state }, payload) {
    const response = await request(DELETE_ONGOING_RIDE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deleteOngoingRide) {
      return response.data.deleteOngoingRide
    }
  },
  // completed rides
  async getCompletedRides ({ commit, state }, payload) {
    const response = await request(COMPLETED_RIDES_QUERY, payload, state.token)

    if (response && response.data && response.data.rides) {
      return response.data.rides
    }
  },
  async adjustRide ({ commit, state }, payload) {
    const response = await request(ADJUST_RIDE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.adjustRide) {
      return response.data.adjustRide
    }
  },
  async refundRide ({ commit, state }, payload) {
    const response = await request(REFUND_RIDE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.refundRide) {
      return response.data.refundRide
    }
  },
  // users
  async getUsers ({ commit, state }, payload) {
    const response = await request(USERS_QUERY, payload, state.token)

    if (response && response.data && response.data.users) {
      return response.data.users
    }
  },
  async toggleTestUser ({ commit, state }, payload) {
    const response = await request(TOGGLE_TEST_USER_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.toggleTestUser) {
      return response.data.toggleTestUser
    }
  },
  async blockUser ({ commit, state }, payload) {
    const response = await request(BLOCK_USER_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.blockUser) {
      return response.data.blockUser
    }
  },
  async unblockUser ({ commit, state }, payload) {
    const response = await request(UNBLOCK_USER_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.unblockUser) {
      return response.data.unblockUser
    }
  },
  async getUserProfile ({ commit, state }, payload) {
    const response = await request(USER_PROFILE_QUERY, payload, state.token)

    if (response && response.data && response.data.users) {
      return response.data.users
    }
  },
  async createVoucherPayment ({ commit, state }, payload) {
    const response = await request(CREATE_VOUCHER_PAYMENT_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createVoucherPayment) {
      return response.data.createVoucherPayment
    }
  },
  async createDebitPayment ({ commit, state }, payload) {
    const response = await request(CREATE_DEBIT_PAYMENT_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createDebitPayment) {
      return response.data.createDebitPayment
    }
  },
  // payments
  async getPayments ({ commit, state }, payload) {
    const response = await request(PAYMENTS_QUERY, payload, state.token)

    if (response && response.data && response.data.payments) {
      return response.data.payments
    }
  },
  async getPayment ({ commit, state }, payload) {
    const response = await request(PAYMENT_QUERY, payload, state.token)

    if (response && response.data && response.data.payment) {
      return response.data.payment
    }
  },
  async captureRazorpayPayment ({ commit, state }, payload) {
    const response = await request(CAPTURE_RAZORPAY_PAYMENT_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.captureRazorpayPayment) {
      return response.data.captureRazorpayPayment
    }
  },
  async refundOnlinePayment ({ commit, state }, payload) {
    const response = await request(REFUND_ONLINE_PAYMENT_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.refundOnlinePayment) {
      return response.data.refundOnlinePayment
    }
  },
  async refundPassPurchase ({ commit, state }, payload) {
    const response = await request(REFUND_PASS_PURCHASE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.refundPassPurchase) {
      return response.data.refundPassPurchase
    }
  },
  // operator logs
  async getOperatorLogs ({ commit, state }, payload) {
    const response = await request(OPERATOR_LOGS_QUERY, payload, state.token)

    if (response && response.data && response.data.operatorLogs) {
      return response.data.operatorLogs
    }
  },
  // bikes
  async getBikes ({ commit, state }, payload) {
    const response = await request(BIKES_QUERY, payload, state.token)

    if (response && response.data && response.data.bikes) {
      return response.data.bikes
    }
  },
  async updateBikeHexcode ({ commit, state }, payload) {
    const response = await request(UPDATE_BIKE_HEXCODE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateBikeHexcode) {
      return response.data.updateBikeHexcode
    }
  },
  async updateBikeState ({ commit, state }, payload) {
    const response = await request(UPDATE_BIKE_STATE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateBikeState) {
      return response.data.updateBikeState
    }
  },
  async updateBikeEcosystem ({ commit, state }, payload) {
    const response = await request(UPDATE_BIKE_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateBikeEcosystem) {
      return response.data.updateBikeEcosystem
    }
  },
  // pending bikes
  async getPendingBikes ({ commit, state }, payload) {
    const response = await request(PENDING_BIKES_QUERY, payload, state.token)

    if (response && response.data && response.data.pendingBikes) {
      return response.data.pendingBikes
    }
  },
  async deleteAllPendingBikes ({ commit, state }, payload) {
    const response = await request(DELETE_ALL_PENDING_BIKES_MUTATION, payload, state.token)

    if (response && response.data && response.data.deleteAllPendingBikes) {
      return response.data.deleteAllPendingBikes
    }
  },
  async deployPendingBike ({ commit, state }, payload) {
    const response = await request(DEPLOY_PENDING_BIKE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deployPendingBike) {
      return response.data.deployPendingBike
    }
  },
  async deletePendingBike ({ commit, state }, payload) {
    const response = await request(DELETE_PENDING_BIKE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deletePendingBike) {
      return response.data.deletePendingBike
    }
  },
  async createBike ({ commit, state }, payload) {
    const response = await request(CREATE_BIKE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createBike) {
      return response.data.createBike
    }
  },
  // docks
  async getDocks ({ commit, state }, payload) {
    const response = await request(DOCKS_QUERY, payload, state.token)

    if (response && response.data && response.data.docks) {
      return response.data.docks
    }
  },
  async createDock ({ commit, state }, payload) {
    const response = await request(CREATE_DOCK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createDock) {
      return response.data.createDock
    }
  },
  async updateDock ({ commit, state }, payload) {
    const response = await request(UPDATE_DOCK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateDock) {
      return response.data.updateDock
    }
  },
  async deleteDock ({ commit, state }, payload) {
    const response = await request(DELETE_DOCK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deleteDock) {
      return response.data.deleteDock
    }
  },
  // ride ratings
  async getRideRatings ({ commit, state }, payload) {
    const response = await request(RIDE_RATINGS_QUERY, payload, state.token)

    if (response && response.data && response.data.rideRatings) {
      return response.data.rideRatings
    }
  },
  // tasks
  async getTasks ({ commit, state }, payload) {
    const response = await request(TASKS_QUERY, payload, state.token)

    if (response && response.data && response.data.tasks) {
      return response.data.tasks
    }
  },
  async getTaskIssues ({ commit, state }, payload) {
    const response = await request(TASK_ISSUES_QUERY, payload, state.token)

    if (response && response.data && response.data.taskIssues) {
      return response.data.taskIssues
    }
  },
  async getTasksStats ({ commit, state }, payload) {
    const response = await request(TASKS_STATS_QUERY, payload, state.token)

    if (response && response.data && response.data.tasksStats) {
      return response.data.tasksStats
    }
  },
  async createTask ({ commit, state }, payload) {
    const response = await request(CREATE_TASK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createTask) {
      return response.data.createTask
    }
  },
  async createTaskPhotoUrl ({ commit, state }, payload) {
    const response = await request(TASK_PHOTO_URL_MUTATION, payload, state.token)

    if (response && response.data && response.data.createTaskPhotoUrl) {
      return response.data.createTaskPhotoUrl
    }
  },
  async updateTask ({ commit, state }, payload) {
    const response = await request(UPDATE_TASK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateTask) {
      return response.data.updateTask
    }
  },
  async assignTasks ({ commit, state }, payload) {
    const response = await request(ASSIGN_TASKS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.assignTasks) {
      return response.data.assignTasks
    }
  },
  async deleteTasks ({ commit, state }, payload) {
    const response = await request(DELETE_TASKS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deleteTasks) {
      return response.data.deleteTasks
    }
  },
  // referrals
  async getReferrals ({ commit, state }, payload) {
    const response = await request(REFERRALS_QUERY, payload, state.token)

    if (response && response.data && response.data.referrals) {
      return response.data.referrals
    }
  },
  // rewards
  async getRewards ({ commit, state }, payload) {
    const response = await request(REWARDS_QUERY, payload, state.token)

    if (response && response.data && response.data.rewards) {
      return response.data.rewards
    }
  },
  // analytics
  async getCumulativeAnalytics ({ commit, state }, payload) {
    const response = await request(CUMULATIVE_ANALYTICS_QUERY, payload, state.token)

    if (response && response.data.analytics) {
      return response.data.analytics
    }
  },
  // operators
  async getOperators ({ commit, state }, payload) {
    const response = await request(OPERATORS_QUERY, payload, state.token)

    if (response && response.data && response.data.operators) {
      return response.data.operators
    }
  },
  async updateOperatorRole ({ commit, state }, payload) {
    const response = await request(UPDATE_OPERATOR_ROLE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateOperatorRole) {
      return response.data.updateOperatorRole
    }
  },
  async createOperator ({ commit, state }, payload) {
    const response = await request(CREATE_OPERATOR_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createOperator) {
      return response.data.createOperator
    }
  },
  // operator profile
  async getOperatorProfile ({ commit, state }, payload) {
    const response = await request(OPERATOR_PROFILE_QUERY, payload, state.token)

    if (response && response.data && response.data.operators) {
      return response.data.operators
    }
  },
  async blockOperator ({ commit, state }, payload) {
    const response = await request(BLOCK_OPERATOR_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.blockOperator) {
      return response.data.blockOperator
    }
  },
  async unblockOperator ({ commit, state }, payload) {
    const response = await request(UNBLOCK_OPERATOR_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.unblockOperator) {
      return response.data.unblockOperator
    }
  },
  async addOperatorEcosystem ({ commit, state }, payload) {
    const response = await request(ADD_OPERATOR_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.addOperatorEcosystem) {
      return response.data.addOperatorEcosystem
    }
  },
  async deleteOperatorEcosystem ({ commit, state }, payload) {
    const response = await request(DELETE_OPERATOR_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.deleteOperatorEcosystem) {
      return response.data.deleteOperatorEcosystem
    }
  },
  // passes
  async getPasses ({ commit, state }, payload) {
    const response = await request(PASSES_QUERY, payload, state.token)

    if (response && response.data && response.data.passes) {
      return response.data.passes
    }
  },
  async getPass ({ commit, state }, payload) {
    const response = await request(PASS_QUERY, payload, state.token)

    if (response && response.data && response.data.pass) {
      return response.data.pass
    }
  },
  async getEcosystemsWithoutPasses ({ commit, state }, payload) {
    const response = await request(ECOSYSTEMS_WITHOUT_PASSES_QUERY, payload, state.token)

    if (response && response.data && response.data.ecosystemsWithoutPasses) {
      return response.data.ecosystemsWithoutPasses
    }
  },
  async createPass ({ commit, state }, payload) {
    const response = await request(CREATE_PASS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createPass) {
      return response.data.createPass
    }
  },
  async togglePassStatus ({ commit, state }, payload) {
    const response = await request(TOGGLE_PASS_STATUS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.togglePassStatus) {
      return response.data.togglePassStatus
    }
  },
  async addPassEcosystems ({ commit, state }, payload) {
    const response = await request(ADD_PASS_ECOSYSTEMS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.addPassEcosystems) {
      return response.data.addPassEcosystems
    }
  },
  async removePassEcosystems ({ commit, state }, payload) {
    const response = await request(REMOVE_PASS_ECOSYSTEMS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.removePassEcosystems) {
      return response.data.removePassEcosystems
    }
  },
  // ecosystems
  async getPaginatedEcosystems ({ commit, state }, payload) {
    const response = await request(PAGINATED_ECOSYSTEMS_QUERY, payload, state.token)

    if (response && response.data && response.data.paginatedEcosystems) {
      return response.data.paginatedEcosystems
    }
  },
  async createEcosystem ({ commit, state }, payload) {
    const response = await request(CREATE_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createEcosystem) {
      return response.data.createEcosystem
    }
  },
  async updateEcosystem ({ commit, state }, payload) {
    const response = await request(UPDATE_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateEcosystem) {
      return response.data.updateEcosystem
    }
  },
  // audit operator logs
  async getOperatorLogForAudit ({ commit, state }, payload) {
    const response = await request(OPERATOR_LOG_FOR_AUDIT_QUERY, payload, state.token)

    if (response && response.data && response.data.operatorLogForAudit) {
      return response.data.operatorLogForAudit
    }
  },
  async auditOperatorLog ({ commit, state }, payload) {
    const response = await request(AUDIT_OPERATOR_LOG_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.auditOperatorLog) {
      return response.data.auditOperatorLog
    }
  },
  // store deals
  async getStoreDeals ({ commit, state }, payload) {
    const response = await request(STORE_DEALS_QUERY, payload, state.token)

    if (response && response.data && response.data.storeDeals) {
      return response.data.storeDeals
    }
  },
  async getStoreDeal ({ commit, state }, payload) {
    const response = await request(STORE_DEAL_QUERY, payload, state.token)

    if (response && response.data && response.data.storeDeal) {
      return response.data.storeDeal
    }
  },
  // admins
  async getAdmins ({ commit, state }, payload) {
    const response = await request(ADMINS_QUERY, payload, state.token)

    if (response && response.data && response.data.admins) {
      return response.data.admins
    }
  },
  async getAdminPermissions ({ commit, state }, payload) {
    const response = await request(ADMIN_PERMISSIONS_QUERY, payload, state.token)

    if (response && response.data && response.data.adminPermissions) {
      return response.data.adminPermissions
    }
  },
  async createAdminPermission ({ commit, state }, payload) {
    const response = await request(CREATE_ADMIN_PERMISSION_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createAdminPermission) {
      return response.data.createAdminPermission
    }
  },
  async getAdmin ({ commit, state }, payload) {
    const response = await request(ADMIN_QUERY, payload, state.token)

    if (response && response.data && response.data.admins) {
      return response.data.admins
    }
  },
  async addAdminPermission ({ commit, state }, payload) {
    const response = await request(ADD_ADMIN_PERMISSION_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.addAdminPermission) {
      return response.data.addAdminPermission
    }
  },
  async deleteAdminPermission ({ commit, state }, payload) {
    const response = await request(REMOVE_ADMIN_PERMISSION_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.removeAdminPermission) {
      return response.data.removeAdminPermission
    }
  },
  async blockAdmin ({ commit, state }, payload) {
    const response = await request(BLOCK_ADMIN_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.blockAdmin) {
      return response.data.blockAdmin
    }
  },
  async unblockAdmin ({ commit, state }, payload) {
    const response = await request(UNBLOCK_ADMIN_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.unblockAdmin) {
      return response.data.unblockAdmin
    }
  },
  // logout
  logout ({ commit, state }) {
    commit('CLEAR_STATE')
    commit('SHOW_SNACKBAR', { message: 'You have been logged out.' })
  }
}
